import React from "react";
import Tilty from "react-tilty";

const BcrumbBannerOne = ({ title, paragraph, styleClass, mainThumb }) => {
  return (
    <div className="breadcrum-area breadcrumb-banner">
      <div className="container containerFlex">
        <div className="col-12 col-md-6">
          <h1
            className="title h2"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h1>
          <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
        </div>
        <div className={`col-6 col-md-3`}>
          <Tilty perspective={2000} reset={false}>
            <img src={process.env.PUBLIC_URL + mainThumb} alt="Illustration" />
          </Tilty>
        </div>
      </div>
      <ul className="shape-group-8 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-9.webp"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-19.webp"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.webp"}
            alt="Line"
          />
        </li>
      </ul>
    </div>
  );
};

export default BcrumbBannerOne;
