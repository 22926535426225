import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProcesstData from "../../data/process/ProcessData.json";
import Tilty from "react-tilty";
import { useTranslation } from "react-i18next";
const getProcesstData = ProcesstData;

const ProcessOne = () => {
  const { t } = useTranslation();
  return (
    <div className="section section-padding bg-color-light pb--70">
      <SectionTitle
        subtitle={t("El-Delta Cloud")}
        title={t("Our main features")}
        description={t(
          "We offer different types of cloud computing including IaaS and SaaS as well as serverless"
        )}
        textAlignment=""
        textColor="mb--90"
      />
      <div className="container">
        {getProcesstData.map((data) => (
          <div
            key={data.id}
            className={`process-work ${
              data.id % 2 === 0 ? "content-reverse" : ""
            }`}
          >
            <Tilty perspective={2000}>
              <div className="thumbnail">
                <img
                  width={350}
                  src={process.env.PUBLIC_URL + data.thumb}
                  alt="Thumbnail"
                />
              </div>
            </Tilty>
            <div className="content">
              <span className="subtitle">{t(`${data.subtitle}`)}</span>
              <h3 className="title">{t(`${data.title}`)}</h3>
              <p>{t(`${data.paragraph}`)}</p>
            </div>
          </div>
        ))}
      </div>
      <ul className="shape-group-17 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-24.webp"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-23.webp"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.webp"}
            alt="Line"
          />
        </li>
        {/* <li className="shape shape-4">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.webp"}
            alt="Line"
          />
        </li> */}
        <li className="shape shape-5">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.webp"}
            alt="Line"
          />
        </li>
        <li className="shape shape-6">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.webp"}
            alt="Line"
          />
        </li>
      </ul>
    </div>
  );
};

export default ProcessOne;
