import React, { useState } from "react";
import Logo from "../../elements/logo/Logo";
import Nav from "../../common/header/Nav";
import StickyHeader from "./StickyHeader";
import Cookies from "js-cookie";
import i18n from "../../i18n";
import MobileMenu from "./MobileMenu";
import { useNavigate } from "react-router-dom";

const HeaderOne = () => {
  const [lang, setLang] = useState("ar");
  const navigate = useNavigate();

  const sticky = StickyHeader(100);

  const MobileMenuHandler = () => {
    document.querySelector(".mobilemenu-popup").classList.toggle("show");
    document.querySelector("body").classList.toggle("mobilemenu-show");

    var elements = document.querySelectorAll(
      ".mobilemenu-popup .menu-item-has-children > a"
    );

    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".axil-submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
  };

  const pagePath = window.location.pathname;

  const changeLang = (language) => {
    if (language == "en") {
      let langPath = pagePath.replace(/^\/ar/, "/en");
      navigate(langPath);
      setLang("ar");
      document.getElementsByTagName("body")[0].style.fontFamily = "deltaFontEn";
      document.body.dir = "ltr";
    } else if (language == "ar") {
      let langPath = pagePath.replace(/^\/en/, "/ar");
      navigate(langPath);
      setLang("en");
      document.getElementsByTagName("body")[0].style.fontFamily = "deltaFontAr";
      document.body.dir = "rtl";
    }
  };

  return (
    <>
      <header className="header axil-header header-style-1">
        <div className={`axil-mainmenu ${sticky ? "axil-sticky" : ""}`}>
          <div className="container">
            <div className="header-navbar">
              <div className="header-logo">
                <Logo
                  limage="/images/lightlogo.webp"
                  dimage="/images/lightlogo.webp"
                  simage="/images/lightlogo.webp"
                />
              </div>
              <div className="header-main-nav">
                <Nav />
              </div>
              <div className="header-action">
                <ul className="list-unstyled">
                  <li className="sidemenu-btn d-lg-block d-none">
                    <button
                      className="btn-wrap"
                      onClick={() => {
                        changeLang(lang);
                        console.log(window.location.href);

                        i18n.changeLanguage(lang.toLocaleLowerCase());
                      }}
                    >
                      {lang.toUpperCase()}
                    </button>
                  </li>
                  <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                    <button className="btn-wrap" onClick={MobileMenuHandler}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileMenu MobileHandler={MobileMenuHandler} />
    </>
  );
};

export default HeaderOne;
