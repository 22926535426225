import React, { useRef } from "react";
import SEO from "../common/SEO";
import AboutOne from "../component/about/AboutOne";
import BannerOne from "../component/banner/BannerOne";
import ProcessOne from "../component/process/ProcessOne";
import ServicePropOne from "../component/service/ServicePropOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ServiceData from "../data/service/ServiceMain.json";
import { useTranslation } from "react-i18next";

const AllData = ServiceData;
const Home = () => {
  const { t } = useTranslation();
  const aboutRef = useRef(null);

  return (
    <>
      <SEO title="El-Delta Cloud" />

      <main className="main-wrapper">
        {/* <HeaderOne langSelect={lang}/> */}
        <BannerOne />
        <ProcessOne />
        <div className="section section-padding bg-color-light">
          <div className="container">
            <SectionTitle
              subtitle={t(`${"What We Can Do For You"}`)}
              title={t(`${"Services we can <br> help you with"}`)}
              description=""
              textAlignment="heading-left"
              textColor=""
            />
            <div className="row">
              <ServicePropOne
                colSize="col-lg-4 col-md-6"
                serviceStyle="service-style-2"
                itemShow="6"
                marginTop="no"
                AllData={AllData}
              />
            </div>
          </div>
        </div>
        {/* <ServiceOne /> */}
        <div ref={aboutRef} id="about">
          <AboutOne />
        </div>
      </main>
    </>
  );
};

export default Home;
