import React from "react";
import SEO from "../common/SEO";

import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import FormTwo from "../component/contact/FormTwo";
import SectionTitle from "../elements/section-title/SectionTitle";
import { useTranslation } from "react-i18next";
import { FaMapMarked, FaPhone, FaMailBulk } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const Contact = (data) => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title="Blog Grid" />

      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne
          title={t("Contact Us")}
          page={t("Contact Us")}
          mainThumb={data.data[0].pageImg}
        />

        <div className="section section-padding bg-color-light">
          <div className="container">
            <h2 style={{ color: "#277CB9" }}>{t("Find Us")}</h2>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="services-grid service-style-2">
                  <div className="content">
                    <div className="phone-number">
                      <h5 className="title">
                        <FaMapMarked className="mx-2" />
                        {t("Address")}
                      </h5>
                    </div>
                    <a>
                      {t(
                        "7 ElNaser Road Elforsan Towers (1), Nasr City, Cairo, Egypt"
                      )}
                    </a>

                    {/* <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`} className="more-btn">Find out more</Link> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-grid service-style-2">
                  <div className="content">
                    <h5 className="title">
                      <FaPhone className="mx-2" />
                      {t("Phone")}
                    </h5>
                    <a href="tel:15950">15950</a>
                    {/* <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`} className="more-btn">Find out more</Link> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="services-grid service-style-2">
                  <div className="content">
                    <h5 className="title">
                      <FaMailBulk className="mx-2" /> {t("Mail")}
                    </h5>
                    <a href="mailto:info@el-deltacloud.com">
                      info@el-deltacloud.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-padding">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">{t("HAVE A QUESTION")}</h3>
                  <p>
                    {t(
                      "Fill up the Form and ou team will get back to within 24 hrs"
                    )}
                  </p>
                  <FormTwo />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div
                  className="flat-map wow fadeIn animated"
                  data-wow-delay="0.3ms"
                  data-wow-duration="1000ms"
                  style={{ height: "430px" }}
                >
                  <iframe
                    title="map"
                    className="map-content"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d549.1098903629239!2d31.353165440940163!3d30.071322538355645!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583f50a55176c9%3A0xd21b8935243eb8db!2sEldelta%20Electronic%20Systems!5e0!3m2!1sen!2seg!4v1700744008069!5m2!1sen!2seg"
                    width="100%"
                    height="100%"
                    allowFullScreen=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" /> */}
      </main>
      <ToastContainer />
    </>
  );
};

export default Contact;
