import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PricingData from "../../data/pricing/PricingData.json";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const getPriceingData = PricingData;

const billed = [
  {
    id: 1,
    label: "Shared CPU",
  },
  {
    id: 2,
    label: "Compute Optimized",
  },
  {
    id: 3,
    label: "Memory Optimized",
  },
  {
    id: 4,
    label: "GPU Enabled",
  },
  {
    id: 5,
    label: "NVMe Enabled",
  },
];
const mh = [
  {
    id: 1,
    label: "Monthly",
  },
  {
    id: 2,
    label: "Hourly",
  },
];
const PricingOne = () => {
  const { t } = useTranslation();
  const [isActive, setActive] = useState("");
  const [monHour, setmonHour] = useState("");
  useEffect(() => {
    setActive(billed[0].label);
    setmonHour(mh[0].label);
  }, []);

  const handleChange = (e) => {
    let map = new Map();
    map.set("وحدات المعالجة المشتركة", "Shared CPU");
    map.set("الحوسبة الأمثل", "Compute Optimized");
    map.set("الذاكرة الأمثل", "Memory Optimized");
    map.set("تمكين وحدات المعالجة", "GPU Enabled");
    map.set("تمكين الذاكرة السريعة غير المتطايرة", "NVMe Enabled");
    map.set("Shared CPU", "Shared CPU");
    map.set("Compute Optimized", "Compute Optimized");
    map.set("Memory Optimized", "Memory Optimized");
    map.set("GPU Enabled", "GPU Enabled");
    map.set("NVMe Enabled", "NVMe Enabled");
    // console.log(map.get('وحدات المعالجة المشتركة'))
    setActive(map.get(e.target.textContent));
  };
  const handleMonHour = (e) => {
    let map = new Map();
    map.set("Hourly", "Hourly");
    map.set("Monthly", "Monthly");
    map.set("لكل شهر", "Monthly");
    map.set("لكل ساعة", "Hourly");
    setmonHour(map.get(e.target.textContent));
  };

  return (
    <>
      <div className="pricing-billing-duration">
        <ul>
          {billed.map((data) => (
            <li className="nav-item" key={data.id}>
              <button
                onClick={handleChange}
                className={`nav-link ${
                  data.label === isActive ? "active" : ""
                }`}
              >
                {t(`${data.label}`)}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="pricing-billing-duration">
        <ul>
          {mh.map((data) => (
            <li className="nav-item" key={data.id}>
              <button
                onClick={handleMonHour}
                className={`nav-link ${data.label === monHour ? "active" : ""}`}
              >
                {t(`${data.label}`)}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="row mt-5">
        {getPriceingData
          .filter((data) => data.section === isActive)
          .map((data, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
              <div
                className="pricing-table pricing-borderd"
                style={{ direction: "ltr", fontFamily: "deltaFontEn" }}
              >
                <div className="pricing-header">
                  <h3 className="title">{data.plan}</h3>
                  {/* <span className="subtitle">{data.subtitle}</span> */}
                  <div className="price-wrap">
                    <div className="yearly-pricing">
                      <span className="amount">
                        {mh[0].label === monHour ? data.mprice : data.hprice}
                      </span>
                      <span className="duration">
                        {mh[0].label === monHour
                          ? data.mduration
                          : data.hduration}
                      </span>
                    </div>
                  </div>
                  <div className="pricing-btn">
                    <a
                      href="https://my.el-deltacloud.com/auth/login"
                      target="_blank"
                      className="axil-btn btn-large btn-borderd"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="pricing-body">
                  <ul className="list-unstyled">
                    {data.facility.map((data, index) => (
                      <li key={index}>
                        <FaCheck /> {data}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
      </div>
      {getPriceingData.filter((data) => data.section === isActive).length ===
        0 && (
        <div className="col-6 m-auto">
          <h1
            className="title"
            style={{ color: "#277CB9", textAlign: "center" }}
          >
            {t(`Cooming Soon!`)}
          </h1>
        </div>
      )}
    </>
  );
};

export default PricingOne;
