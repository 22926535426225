import React from "react";
import { Link } from "react-router-dom";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";

import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <SEO title="404 Not Found" />

      <Helmet>
        <title>El-Delta Cloud | 404 Not Found</title>
        <meta
          name="description"
          content="El-Delta Cloud is a subsidiary company that is operated by El-Delta for Electronic Systems. We are launching during quarter one, 2023. We consider ourselves the first self-service public cloud in Egypt.
          We offer different types of cloud computing including IaaS and SaaS as well as serverless. El-Delta Cloud delivers virtual infrastructure such as servers, storage, databases, networking, software, analytics, and intelligence via Internet; in order to provide faster innovation and more flexible resources"
        />
        <meta
          name="keywords"
          content="SaaS (Software as a Service), PaaS (Platform as a Service), IaaS (Infrastructure as a Service), XaaS (Anything as a Service), public cloud, private cloud, hybrid cloud, multi-cloud, cloud storage, cloud backup, cloud disaster, recovery, cloud security, cloud compliance, cloud migration, cloud architecture, cloud applications, cloud platforms, cloud infrastructure, cloud consulting, cloud in Egypt "
        />
      </Helmet>

      <main className="main-wrapper">
        <HeaderOne />
        <div className="error-page onepage-screen-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <AnimationOnScroll
                  animateIn="slideInUp"
                  duration={1}
                  delay={300}
                  animateOnce={true}
                >
                  <div className="">
                    <h2 className="title">{t("Page not found")}</h2>

                    <Link
                      to={process.env.PUBLIC_URL + "/"}
                      className="axil-btn btn-fill-primary"
                    >
                      {t("Go Back")}
                    </Link>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-lg-6">
                <AnimationOnScroll
                  animateIn="zoomIn"
                  duration={1}
                  delay={300}
                  animateOnce={true}
                >
                  <div className="thumbnail">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/404.webp"}
                      alt="404"
                    />
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
          <ul className="shape-group-8 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-9.webp"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-27.webp"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.webp"}
                alt="Line"
              />
            </li>
          </ul>
        </div>
      </main>
    </>
  );
};

export default ErrorPage;
