import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BannerOne = () => {
  const { t } = useTranslation();

  return (
    <div className="banner banner-style-1">
      <div className="container">
        <div className="row align-items-center justify-content-center mb-2">
          <div className="col-lg-7 col-12">
            <div className="banner-content">
              <AnimationOnScroll
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}
              >
                <h1 className="title" style={{ color: "#277CB9" }}>
                  {t(`Transform your Business Move To The Cloud`)}
                </h1>
                <span className="subtitle">
                  {t(
                    `We create state of art solutions and services that will take organizations to a premium level of IT infrastructure`
                  )}
                </span>
                <a
                  href="https://my.el-deltacloud.com/auth/login"
                  className="axil-btn btn-fill-primary btn-large"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t(`Get Started`)}
                </a>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="col-lg-5 col-10 shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/cloudImg.webp"}
              alt="Bubble"
            />
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-21">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-39.webp"}
            alt="Bubble"
          />
        </li>

        <li className="shape shape-7">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-41.webp"}
            alt="Bubble"
          />
        </li>
        {/* <li className="shape shape-4">
          <img
            src={process.env.PUBLIC_URL + "/images/others/cloudImg.webp"}
            alt="Bubble"
          />
        </li> */}
      </ul>
    </div>
  );
};

export default BannerOne;
