import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
const storedLang = localStorage.getItem('i18nextLng')

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .use(HttpApi)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: ['en', 'ar'],
    debug: false,
    fallbackLng: 'en',
    saveMissing: true,
    lng: document.querySelector('html')?.lang || 'en',
    // lng: localStorage.getItem("i18nextLng"),
    backend: {
      loadPath: `/assets/locales/{{ lng }}/translation.json`,
    },
    detection: {
      order: ['htmlTag', 'localStorage', 'cookie', 'path', 'subdomain'],
      cashes: ['localStorage'],
    },
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    storedLang, // Add it here
  })

export default i18n
