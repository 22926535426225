import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";

import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.Fragment>
);

reportWebVitals();
