import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";

import SEO from "../common/SEO";
import FaqOne from "../component/faq/FaqOne";
import { useTranslation } from "react-i18next";
const TermsOfUse = (data) => {
  const { t } = useTranslation();
  return (
    <>
      <SEO title="Terms Of Use" />

      <main className="main-wrapper">
        {/* <HeaderOne /> */}
        <BreadCrumbOne
          title={t(`${data.data[0].head}`)}
          page={t(`${data.data[0].head}`)}
          mainThumb={data.data[0].pageImg}
        />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                {data.data.map((ele, id) => (
                  <div className="privacy-policy-content" key={id}>
                    <h4>{t(`${ele.title}`)}</h4>
                    <p>{t(`${ele.description}`)}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <FaqOne /> */}
        {/* <FooterOne parentClass="" /> */}
      </main>
    </>
  );
};

export default TermsOfUse;
