import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import { useEffect } from "react";
// Home Pages Import
import Home from "./pages/Home";

// Service

import ServiceTwo from "./pages/ServiceTwo";

// Pages

import PricingTable from "./pages/PricingTable";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/404";
import ComingSoon from "./pages/ComingSoon";
import TermsOfUse from "./pages/TermsOfUse";

// Css Import
import "./assets/scss/app.scss";

//Service data
import compute from "./data/service/compute.json";
import dns from "./data/service/dns.json";
import firewall from "./data/service/firewall.json";
import monitoring from "./data/service/monitoring.json";
import networking from "./data/service/networking.json";
import onPrem from "./data/service/onPrem.json";
import privateCloud from "./data/service/privateCloud.json";
import recovery from "./data/service/recovery.json";
import storage from "./data/service/storage.json";
import unstructured from "./data/service/unstructured.json";
import paas from "./data/service/paas.json";
import saas from "./data/service/saas.json";
//Industries data
import education from "./data/industries/education.json";
import finance from "./data/industries/finance.json";
import gov from "./data/industries/gov.json";
import medical from "./data/industries/medical.json";
import telecom from "./data/industries/telecom.json";
//support
import contactData from "./data/contact/contactData.json";
import privacyPolicy from "./data/support/privacyPolicy.json";
import sla from "./data/support/sla.json";
import terms from "./data/support/terms.json";
import faq from "./data/support/faq.json";
import feedback from "./data/support/feedback.json";
import usagePolicy from "./data/support/usagePolicy.json";
import HeaderOne from "./common/header/HeaderOne";
import FooterOne from "./common/footer/FooterOne";
import { useTranslation } from "react-i18next";

const contact = contactData;

const PrivacyPolicys = privacyPolicy;
const Sla = sla;
const Terms = terms;
const Faq = faq;
const Feedback = feedback;
const UsagePolicy = usagePolicy;

const Education = education;
const Finance = finance;
const Gov = gov;
const Medical = medical;
const Telecom = telecom;

const Compute = compute;
const Dns = dns;
const Firewall = firewall;
const Monitoring = monitoring;
const Networking = networking;
const OnPrem = onPrem;
const PrivateCloud = privateCloud;
const Recovery = recovery;
const Storage = storage;
const Unstructured = unstructured;
const PaaS = paas;
const SaaS = saas;

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // const language = localStorage.getItem("lang") || "ar";
    const language = window.location.pathname.split("/")[1] || "en";
    i18n.changeLanguage(language);

    if (language === "en") {
      document.body.dir = "ltr";
      document.getElementsByTagName("html")[0].setAttribute("lang", language);
      document.getElementsByTagName("body")[0].style.fontFamily = "deltaFontEn";
    } else {
      document.body.dir = "rtl";
      document.getElementsByTagName("html")[0].setAttribute("lang", language);
      document.getElementsByTagName("body")[0].style.fontFamily = "deltaFontAr";
    }
  }, [i18n]);

  const lang = window.location.pathname.split("/")[1];
  const LangGuard = () => {
    console.log("langggggggggg", lang);
    if (lang !== "en" && lang !== "ar") {
      // Redirect to the not found page
      return <Navigate to="/en/404" replace />;
    } else {
      return <Navigate to={`/${lang}/404`} replace />;
    }
  };
  return (
    <Router>
      <ScrollToTop>
        <HeaderOne />
        <Routes>
          {/* <Route path="/:lang" element={<LangGuard />} /> */}

          <Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/home"}
            element={<Home />}
          />
          {/* Service */}
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/compute"}
            element={<ServiceTwo data={Compute} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/dns"}
            element={<ServiceTwo data={Dns} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/firewall"}
            element={<ServiceTwo data={Firewall} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/monitoring"}
            element={<ServiceTwo data={Monitoring} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/paas"}
            element={<ServiceTwo data={PaaS} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/saas"}
            element={<ServiceTwo data={SaaS} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/networking"}
            element={<ServiceTwo data={Networking} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/onPrem"}
            element={<ServiceTwo data={OnPrem} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/privateCloud"}
            element={<ServiceTwo data={PrivateCloud} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/recovery"}
            element={<ServiceTwo data={Recovery} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/storage"}
            element={<ServiceTwo data={Storage} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/services/unstructured"}
            element={<ServiceTwo data={Unstructured} />}
          />
          {/* Industries  */}
          <Route
            path={process.env.PUBLIC_URL + "/:lang/industries/education"}
            element={<ServiceTwo data={Education} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/industries/finance"}
            element={<ServiceTwo data={Finance} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/industries/gov"}
            element={<ServiceTwo data={Gov} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/industries/medical"}
            element={<ServiceTwo data={Medical} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/industries/telecom"}
            element={<ServiceTwo data={Telecom} />}
          />
          {/* Pages  */}
          <Route
            path={process.env.PUBLIC_URL + "/:lang/packages"}
            element={<PricingTable />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/contact"}
            element={<Contact data={contact} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/404"}
            element={<ErrorPage />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/coming-soon"}
            element={<ComingSoon />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/privacy"}
            element={<TermsOfUse data={PrivacyPolicys} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/sla"}
            element={<TermsOfUse data={Sla} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/usage"}
            element={<TermsOfUse data={UsagePolicy} />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang/terms"}
            element={<TermsOfUse data={Terms} />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/:lang/faq"}
            element={<TermsOfUse data={Faq} />}
          />
          {/* <Route
            path={process.env.PUBLIC_URL + '/feedback'}
            element={<TermsOfUse data={Feedback} />}
          /> */}
          <Route path="*" element={<Navigate to={`${lang}/404`} replace />} />
          <Route path="/:lang/*" element={<LangGuard />} />
          <Route path="/:lang/404" element={<ErrorPage />} />
        </Routes>
        <FooterOne />
      </ScrollToTop>
    </Router>
  );
};

export default App;
