import React from "react";
import { Link } from "react-router-dom";
import Tilty from "react-tilty";
import { useTranslation } from "react-i18next";

const BreadCrumbOne = ({ title, page, mainThumb }) => {
  const { t } = useTranslation();
  return (
    <div className="breadcrum-area">
      <div className="container">
        <div className="breadcrumb">
          <ul className="list-unstyled">
            <li>
              <Link to={process.env.PUBLIC_URL + "/"}>{t("Home")}</Link>
            </li>
            <li
              className="active"
              dangerouslySetInnerHTML={{ __html: page }}
            ></li>
          </ul>
          <div className="container containerFlex">
            <div className="col-12 col-md-6">
              <h1
                className="title h2"
                dangerouslySetInnerHTML={{ __html: title }}
              ></h1>
            </div>
            <div className={`col-6 col-md-3`}>
              <Tilty perspective={2000} reset={false}>
                <img
                  src={process.env.PUBLIC_URL + mainThumb}
                  alt="Illustration"
                />
              </Tilty>
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-8 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-9.webp"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-19.webp"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-6.webp"}
            alt="Line"
          />
        </li>
      </ul>
    </div>
  );
};

export default BreadCrumbOne;
