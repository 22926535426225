import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import Nav from "./Nav";
import Cookies from "js-cookie";
import i18n from "../../i18n";

const MobileMenu = ({ MobileHandler }) => {
  const [lang, setLang] = useState("ar");
  const navigate = useNavigate();

  const pagePath = window.location.pathname;

  const changeLang = (language) => {
    if (language == "en") {
      let langPath = pagePath.replace(/^\/ar/, "/en");
      navigate(langPath);
      setLang("ar");
      document.getElementsByTagName("body")[0].style.fontFamily = "deltaFontEn";
      document.body.dir = "ltr";
    } else if (language == "ar") {
      let langPath = pagePath.replace(/^\/en/, "/ar");
      navigate(langPath);
      setLang("en");
      document.getElementsByTagName("body")[0].style.fontFamily = "deltaFontAr";
      document.body.dir = "rtl";
    }
  };
  return (
    <div className="mobilemenu-popup">
      <div className="mobilemenu-inner">
        <div className="mobilemenu-header">
          <div className="mobile-nav-logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img
                className="light-mode"
                src={process.env.PUBLIC_URL + "/images/lightlogo.webp"}
                width="70%"
                alt="Site Logo"
              />
              <img
                className="dark-mode"
                src={process.env.PUBLIC_URL + "/images/lightlogo.webp"}
                alt="Site Logo"
              />
            </Link>
          </div>
          <button className="mobile-menu-close" onClick={MobileHandler}>
            <FaTimes />
          </button>
        </div>
        <div className="mobilemenu-body">
          <Nav />
          <div className="header-action">
            <ul className="list-unstyled">
              <li className="sidemenu-btn d-lg-block">
                <button
                  className="btn-wrap"
                  onClick={() => {
                    changeLang(lang);
                    console.log(window.location.href);

                    i18n.changeLanguage(lang.toLocaleLowerCase());
                  }}
                >
                  {lang.toUpperCase()}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
