import React from 'react'
import FooterOne from '../common/footer/FooterOne'
import HeaderOne from '../common/header/HeaderOne'
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne'

import SEO from '../common/SEO'
import SectionTitle from '../elements/section-title/SectionTitle'
import ServicePropOne from '../component/service/ServicePropOne'
import { useTranslation } from 'react-i18next'
const ServiceTwo = (data) => {  const { t } = useTranslation()
  return (
    <>
      <SEO title="Service Two" />

      <main className="main-wrapper">
        {/* <HeaderOne /> */}
        <BcrumbBannerOne
          title={t(`${data.data[0].head}`)}
          paragraph={t(`${data.data[0].subHead}`)}
          styleClass=""
          mainThumb={data.data[0].pageImg}
        />
        <div className="section section-padding bg-color-light">
          <div className="container">
            <SectionTitle
              subtitle={t("What We Can Do For You")}
              title={t("Services we can <br> help you with")}
              description=""
              textAlignment="heading-left"
              textColor=""
            />
            <div className="row justify-content-center">
              <ServicePropOne
                colSize="col-lg-4 col-md-6"
                serviceStyle="service-style-2"
                itemShow="12"
                marginTop="no"
                AllData={data.data}
              />
            </div>
          </div>
        </div>

        {/* <FooterOne parentClass="" /> */}
      </main>
    </>
  )
}

export default ServiceTwo
