import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedin,
  FaEnvelopeOpen,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

const FooterOne = ({ parentClass }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");

  const form = useRef();
  const [open, setOpen] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    let templateParams = {
      email: form.current.email.value,
    };
    setOpen(true);

    emailjs
      .send(
        "service_qd2p4vh",
        "template_g9ktyla",
        templateParams,
        "POY5G5HuyrnPeoEBZ"
      )
      .then((res) => {
        toast.success("Mail Sent Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setOpen(false);
      })
      .catch((err) => {
        toast.error("Something went wrong please try again later!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setOpen(false);
      });
    e.target.reset();
  };

  return (
    <>
      <footer className={`footer-area ${parentClass}`}>
        <div className="container">
          <div className="footer-main">
            <div className="row my-5">
              <div className="col-xl-6 col-lg-6">
                <div className="footer-widget border-end">
                  <div className="footer-newsletter">
                    <h2 className="title">{t(`Get in touch!`)}</h2>
                    <div className="footer-top">
                      <div className="footer-social-link">
                        <ul className="list-unstyled">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener"
                              href="https://www.facebook.com/El-Delta-Electronic-Systems-104005772089878"
                            >
                              <FaFacebookF />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noopener"
                              href="https://www.linkedin.com/company/delta-electronic-systems"
                            >
                              <FaTwitter />
                            </a>
                          </li>

                          <li>
                            <a
                              target="_blank"
                              rel="noopener"
                              href="https://www.linkedin.com/company/delta-electronic-systems"
                            >
                              <FaLinkedin />
                            </a>
                          </li>
                          {/* <li>
                          <a target="_blank" href="https://www.instagram.com/">
                            <FaInstagram />
                          </a>
                        </li> */}
                        </ul>
                      </div>
                    </div>
                    <p>
                      {t(
                        `Don’t miss to subscribe to our new feeds, kindly fill the form below`
                      )}
                    </p>
                    <form ref={form} onSubmit={sendEmail}>
                      <div className="input-group">
                        <span className="mail-icon">
                          <FaEnvelopeOpen />
                        </span>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          required
                          placeholder={t("Email address")}
                        />
                        <button className="subscribe-btn" type="submit">
                          {t(`Subscribe`)}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="footer-widget my-3">
                      <h6 className="widget-title">{t(`Services`)}</h6>
                      <div className="footer-menu-link">
                        <ul
                          className="list-unstyled"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0",
                          }}
                        >
                          <div>
                            <li>
                              <Link to={`/${lang}/services/compute`}>
                                {t(`Compute`)}
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${lang}/services/storage`}>
                                {t(`Storage`)}
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${lang}/services/unstructured`}>
                                {t(`Unstructured`)}
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${lang}/services/networking`}>
                                {t(`Networking`)}
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${lang}/services/firewall`}>
                                {t(`Firewall`)}
                              </Link>
                            </li>
                          </div>
                          <div>
                            <li>
                              <Link to={`/${lang}/services/recovery`}>
                                {t(`Recovery`)}
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${lang}/services/dns`}>
                                {t(`DNS`)}
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${lang}/services/monitoring`}>
                                {t(`Monitoring`)}
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${lang}/services/privateCloud`}>
                                {t(`Private Cloud`)}
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${lang}/services/onPrem`}>
                                {t(`On-Premises`)}
                              </Link>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="footer-widget my-3">
                      <h6 className="widget-title">{t(`Industries`)}</h6>
                      <div className="footer-menu-link">
                        <ul className="list-unstyled" style={{ padding: "0" }}>
                          <li>
                            <Link to={`/${lang}/industries/education`}>
                              {t(`Education`)}
                            </Link>
                          </li>
                          <li>
                            <Link to={`/${lang}/industries/medical`}>
                              {t(`Medical`)}
                            </Link>
                          </li>
                          <li>
                            <Link to={`/${lang}/industries/finance`}>
                              {t(`Finance`)}
                            </Link>
                          </li>
                          <li>
                            <Link to={`/${lang}/industries/telecom`}>
                              {t(`Telecom`)}
                            </Link>
                          </li>
                          <li>
                            <Link to={`/${lang}/industries/gov`}>
                              {t(`GOV`)}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="footer-widget my-3">
                      <h6 className="widget-title">{t(`Support`)}</h6>
                      <div className="footer-menu-link">
                        <ul className="list-unstyled" style={{ padding: "0" }}>
                          <li>
                            <Link to={`/${lang}/contact`}>{t(`FAQ`)}</Link>
                          </li>
                          {/* <li>
                            <Link to={`/${lang}/privacy-policy`}>
                              {t(`Feedback`)}
                            </Link>
                          </li> */}

                          <li>
                            <Link to={`/${lang}/sla`}>
                              {t(`Service Level Agreements`)}
                            </Link>
                          </li>
                          <li>
                            <Link to={`/${lang}/usage`}>
                              {t(`Acceptable Usage Policy`)}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row" style={{ width: "99%" }}>
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  {t(` All rights reserved by El-Delta Cloud `)}
                </span>
                <span>© {new Date().getFullYear()} </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={`/${lang}/privacy`}>{t(`Privacy Policy`)}</Link>
                  </li>
                  <li>
                    <Link to={`/${lang}/terms`}>{t(`Terms of Services`)}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Backdrop sx={{ color: "#fff", zIndex: 33 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
    </>
  );
};

export default FooterOne;
