import { useTranslation } from "react-i18next";
import React from "react";

const AboutOne = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="section section-padding-equal bg-color-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-us" id="about">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">{t("About Us")}</span>
                  <h2 className="title mb--40">{t("El-Delta Cloud")}</h2>
                  <p>
                    {t(
                      "El-Delta Cloud is a subsidiary company that is operated by El-Delta for Electronic Systems. We are launching during quarter one, 2023. We consider ourselves the first self-service public cloud in Egypt."
                    )}
                  </p>
                </div>
                <p>
                  {t(
                    "We offer different types of cloud computing including IaaS and SaaS as well as serverless. El-Delta Cloud delivers virtual infrastructure such as servers, storage, databases, networking, software, analytics, and intelligence via Internet; in order to provide faster innovation and more flexible resources. You typically only pay for the cloud services you use, which helps you reduce operating costs, wander your infrastructure more efficiently, and scale as your business requires innovation. You can choose the package that simply suits your business and pay yearly, monthly, or even hourly!"
                  )}
                </p>
              </div>
            </div>

            <div className="col-xl-5 col-lg-6 offset-xl-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/header.webp"}
                alt="Bubble"
              />
            </div>
          </div>
        </div>
        <ul className="shape-group-6 list-unstyled">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-7.webp"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-4.webp"}
              alt="line"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-5.webp"}
              alt="line"
            />
          </li>
        </ul>
      </section>
    </>
  );
};

export default AboutOne;
