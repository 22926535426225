import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";

import SectionTitle from "../elements/section-title/SectionTitle";
import PricingOne from "../component/pricing/PricingOne";
import FaqOne from "../component/faq/FaqOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import { useTranslation } from "react-i18next";
const PricingTable = () => {
  const { t } = useTranslation();
  const priceImg = "/images/icon/price.webp";
  return (
    <>
      <SEO title="Pricing Table" />

      <main className="main-wrapper">
        {/* <HeaderOne /> */}
        <BcrumbBannerOne
          title={t("Packages")}
          paragraph={t(
            "We provide you with powerful block, object and file cloud storages for all your data needs. Streamline your workloads and outdated storage systems with efficeint and modern storage including SSD and NVME based technologies"
          )}
          styleClass=""
          mainThumb={process.env.PUBLIC_URL + priceImg}
        />
        <div className="section section-padding">
          <div className="container">
            <SectionTitle
              subtitle={t("Pricing Plan")}
              title={t("Find the Right Plan")}
              description={t("Flexible pricing options")}
              textAlignment="mb-0"
              textColor=""
            />
            <PricingOne />
          </div>
        </div>
        {/* <FaqOne /> */}
        {/* <CtaLayoutOne /> */}
        {/* <FooterOne parentClass="" /> */}
      </main>
    </>
  );
};

export default PricingTable;
