import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const NestedMenuItem = ({ linkTo, label, hideSidebar }) => (
  <li onClick={hideSidebar}>
    <Link to={linkTo}>{label}</Link>
  </li>
);

const Nav = () => {
  const { t } = useTranslation();
  const aboutRef = useRef(null);
  const lang = localStorage.getItem("i18nextLng");

  function hideSidebar() {
    const elements = document.getElementsByClassName("show");
    elements[0]?.classList.remove("show");
  }

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  function handleAboutClick() {
    const aboutElement = document.getElementById("about");

    if (aboutElement && isMounted) {
      aboutElement.scrollIntoView({ behavior: "smooth" });
    }
  }
  const menuItems = [
    // ... (other menu items)
    {
      linkenTo: `/${lang}/home`,
      label: "Home",
    },
    {
      linkenTo: `/${lang}/home#about`,
      label: "About",
    },

    {
      label: "Services",
      children: [
        {
          label: "SAAS",
          children: [
            { linkTo: `/${lang}/services/web-hosting`, label: "Web Hosting" },
            { linkTo: `/${lang}/services/erp`, label: "ERP" },
            { linkTo: `/${lang}/services/mail-hosting`, label: "Mail Hosting" },
            // Add more child items as needed
          ],
        },
        {
          label: "IAAS",
          children: [
            { linkTo: `/${lang}/services/compute`, label: "Compute" },
            { linkTo: `/${lang}/services/storage`, label: "Storage" },
            { linkTo: `/${lang}/services/unstructured`, label: "Unstructured" },
            // Add more child items as needed
          ],
        },
        {
          label: "NAAS",
          children: [
            { linkTo: `/${lang}/services/networking`, label: "Networking" },
            { linkTo: `/${lang}/services/firewall`, label: "Firewall" },
            { linkTo: `/${lang}/services/dns`, label: "DNS" },
            // Add more child items as needed
          ],
        },
        // ... (other service items)
      ],
    },
    {
      linkenTo: `/${lang}/packages`,
      label: "Packages",
    },
    {
      label: "Support",
      children: [
        { linkTo: `/${lang}/services/faq`, label: "FAQ" },
        { linkTo: `/${lang}/services/contact`, label: "Contact" },
        { linkTo: `/${lang}/services/terms`, label: "Terms" },
        { linkTo: `/${lang}/services/privacy`, label: "Privacy" },
        { linkTo: `/${lang}/services/sla`, label: "SLA" },
        { linkTo: `/${lang}/services/usage`, label: "Usage" },
      ],
    },
    // ... (other menu items)
  ];
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li onClick={hideSidebar}>
          <Link to={`/${lang}/home`}>{t(`Home`)}</Link>
        </li>
        <li onClick={hideSidebar}>
          <Link to={`/${lang}/home#about`} onClick={handleAboutClick}>
            {t(`About Us`)}
          </Link>
        </li>

        <li className="menu-item-has-children">
          <Link to="#">
            {t(`Services`)} <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li className="subMenu-item-has-children">
              <li>
                <Link to="#">
                  {t(`IaaS`)} <FaAngleDown />
                </Link>
              </li>
              <ul className="axil-submenu-children">
                <li onClick={hideSidebar}>
                  <Link to={`/${lang}/services/compute`}>{t(`Compute`)}</Link>
                </li>
                <li onClick={hideSidebar}>
                  <Link to={`/${lang}/services/storage`}>{t(`Storage`)}</Link>
                </li>
                <li onClick={hideSidebar}>
                  <Link to={`/${lang}/services/unstructured`}>
                    {t(`Unstructured`)}
                  </Link>
                </li>
              </ul>
            </li>

            <li className="subMenu-item-has-children">
              <li>
                <Link to="#">
                  {t(`NaaS`)} <FaAngleDown />
                </Link>
              </li>
              <ul className="axil-submenu-children">
                <li onClick={hideSidebar}>
                  <Link to={`/${lang}/services/networking`}>
                    {t(`Networking`)}
                  </Link>
                </li>
                <li onClick={hideSidebar}>
                  <Link to={`/${lang}/services/firewall`}>{t(`Firewall`)}</Link>
                </li>
                <li onClick={hideSidebar}>
                  <Link to={`/${lang}/services/dns`}>{t(`DNS`)}</Link>
                </li>
              </ul>
            </li>

            <li onClick={hideSidebar}>
              <Link to={`/${lang}/services/paas`}>{t(`PaaS`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/services/saas`}>{t(`SaaS`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/services/recovery`}>{t(`Recovery`)}</Link>
            </li>

            <li onClick={hideSidebar}>
              <Link to={`/${lang}/services/monitoring`}>{t(`Monitoring`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/services/privateCloud`}>
                {t(`Private Cloud`)}
              </Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/services/onPrem`}>{t(`On-Premises`)}</Link>
            </li>
            {/* <li>
              <Link to={process.env.PUBLIC_URL + '/404'}>404 Page</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + '/coming-soon'}>
                Coming Soon
              </Link>
            </li> */}
          </ul>
        </li>

        <li className="menu-item-has-children">
          <Link to="#">
            {t(`Industries`)} <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/industries/education`}>{t(`Education`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/industries/medical`}>{t(`Medical`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/industries/finance`}>{t(`Finance`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/industries/telecom`}>{t(`Telecom`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/industries/gov`}>{t(`GOV`)}</Link>
            </li>
          </ul>
        </li>
        <li onClick={hideSidebar}>
          <Link to={`/${lang}/packages`}>{t(`Packages`)}</Link>
        </li>

        <li className="menu-item-has-children">
          <Link to="#">
            {t(`Support`)} <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/faq`}>{t(`FAQ`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/contact`}>{t(`Contact Us`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/terms`}>{t(`Terms of Services`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/privacy`}>{t(`Privacy Policy`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/sla`}>{t(`Service Level Agreements`)}</Link>
            </li>
            <li onClick={hideSidebar}>
              <Link to={`/${lang}/usage`}>{t(`Acceptable Usage Policy`)}</Link>
            </li>
          </ul>
        </li>

        <a
          href="https://my.el-deltacloud.com/auth/login"
          target="_blank"
          rel="noreferrer"
          className="axil-btn btn-fill-primary btn-large"
        >
          {t(`Get Started`)}
        </a>
      </ul>
    </nav>
  );
};

export default Nav;
