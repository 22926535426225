import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

const FormTwo = () => {
  const form = useRef();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    let templateParams = {
      name: form.current.appName.value,
      email: form.current.email.value,
      subject: form.current.phone.value,
      comments: form.current.notes.value,
    };
    setOpen(true);

    emailjs
      .send(
        "service_qd2p4vh",
        "template_g9ktyla",
        templateParams,
        "POY5G5HuyrnPeoEBZ"
      )
      .then((res) => {
        toast.success("Mail Sent Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setOpen(false);
      })
      .catch((err) => {
        toast.error("Something went wrong please try again later!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setOpen(false);
      });
    e.target.reset();
  };

  //   setTimeout(() => {
  //     showresult(false);
  //   }, 5000);

  return (
    <>
      <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
        <div className="form-group">
          <label>{t("Name")}</label>
          <input type="text" className="form-control" name="appName" required />
        </div>
        <div className="form-group">
          <label>{t("Email")}</label>
          <input type="email" className="form-control" name="email" required />
        </div>
        <div className="form-group">
          <label>{t("Phone")}</label>
          <input type="tel" className="form-control" name="phone" required />
        </div>
        <div className="form-group mb--40">
          <label>{t("How can we help you?")}</label>
          <textarea className="form-control" name="notes" rows="4"></textarea>
        </div>
        <div className="form-group">
          <button
            type="submit"
            className="axil-btn btn-fill-primary btn-fluid btn-primary"
            name="submit-btn"
          >
            {t("Send")}
          </button>
        </div>
        {/* <div className="form-group">{result ? <Result /> : null}</div> */}

        {/* Same as */}
      </form>
      <Backdrop sx={{ color: "#fff", zIndex: 33 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FormTwo;
